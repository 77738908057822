import {getSiteAbbreviationForProperties} from "../../../SiteConfig/siteUtilities";
import {locations} from "../../../Utilities/Location";
import {findObjectArrayByField} from "../../../Utilities/Types/arrayUtilities";
import {allow, policyEvents} from "../../Authorize";

const dashboardLink = (isLoggedIn, isAdmin) => isLoggedIn
    ? !allow(policyEvents.ADMIN_DASHBOARD) && isAdmin ? locations.REPORTS.link : locations.DASHBOARD.link
    : locations.SIGN_IN.link;
const catalogLink = () => locations.CATALOG.link;
const catalogPageLink = (isLoggedIn) => isLoggedIn ? locations.DASHBOARD.link : locations.LANDING.link;
const landingLink = () => locations.LANDING.link;
const dashboardPageLink = (isLoggedIn, isAdmin) => isAdmin ? locations.LANDING.link : locations.CATALOG.link;
const disabledLink = () => "";
export const disableHeaderNavigation = () => "disableHeaderNavigation";

export const isHeaderNavHidden = value => value === disableHeaderNavigation();

const sharedNavigateTo = [
    {
        onPagePath: locations.DASHBOARD.path,
        navigation: dashboardPageLink,
    },
    {
        onPagePath: locations.SIGN_IN.path,
        navigation: landingLink,
    },
];

const aphNavigateTo = [
    {
        onPagePath: locations.FORGOT_PASSWORD.path,
        navigation: catalogLink,
    },
    {
        onPagePath: locations.REGISTER.path,
        navigation: catalogLink,
    },
    {
        onPagePath: locations.UPDATE_PASSWORD.path,
        navigation: catalogLink,
    },
    {
        onPagePath: locations.SIGN_IN.path,
        navigation: catalogLink,
    },
    {
        onPagePath: locations.CATALOG_PROFESSIONAL_COMMUNITY.path,
        navigation: landingLink,
    },
    {
        onPagePath: locations.COURSE_PROFESSIONAL_COMMUNITIES_CONTENT.path,
        navigation: () => locations.CATALOG_PROFESSIONAL_COMMUNITY.link,
    },
];

const fgrbiNavigateTo = [
    {
        onPagePath: locations.FORGOT_PASSWORD.path,
        navigation: disableHeaderNavigation,
    },
    {
        onPagePath: locations.LANDING.path,
        navigation: disableHeaderNavigation,
    },
    {
        onPagePath: locations.UPDATE_PASSWORD.path,
        navigation: disableHeaderNavigation,
    },
    {
        onPagePath: locations.SIGN_IN.path,
        navigation: disableHeaderNavigation,
    },
    {
        onPagePath: locations.DASHBOARD.path,
        navigation: disabledLink,
    },
];

const earlyAccessNavigateTo = [
    {
        onPagePath: locations.FORGOT_PASSWORD.path,
        navigation: disabledLink,
    },
    {
        onPagePath: locations.LANDING.path,
        navigation: disabledLink,
    },
    {
        onPagePath: locations.UPDATE_PASSWORD.path,
        navigation: disabledLink,
    },
    {
        onPagePath: locations.SIGN_IN.path,
        navigation: disabledLink,
    },
    {
        onPagePath: locations.DASHBOARD.path,
        navigation: disabledLink,
    },
    {
        onPagePath: locations.REGISTER.path,
        navigation: disabledLink,
    },
];

const fcrrNavigateTo = [

];

const maprNavigateTo = [
    {
        onPagePath: locations.DASHBOARD.path,
        navigation: (isLoggedIn, isAdmin) => isAdmin ? locations.LANDING.link : disabledLink()
    }
];

const ficwNavigateTo = [

];

const doeOssNavigateTo = [
    {
        onPagePath: locations.CATALOG.path,
        navigation: catalogPageLink,
    },
    {
        onPagePath: locations.CATEGORY.path,
        navigation: catalogPageLink,
    },
    {
        onPagePath: locations.FORGOT_PASSWORD.path,
        navigation: landingLink,
    },
    {
        onPagePath: locations.LANDING.path,
        navigation: disableHeaderNavigation,
    },
    {
        onPagePath: locations.UPDATE_PASSWORD.path,
        navigation: landingLink,
    }
];

const dohNavigateTo = [
    {
        onPagePath: locations.DASHBOARD.path,
        navigation: disabledLink,
    }
];

const pdaNavigateTo = [
    {
        onPagePath: locations.CATALOG.path,
        navigation: catalogPageLink,
    },
    {
        onPagePath: locations.CATEGORY.path,
        navigation: catalogPageLink,
    },
    {
        onPagePath: locations.FORGOT_PASSWORD.path,
        navigation: landingLink,
    },
    {
        onPagePath: locations.LANDING.path,
        navigation: disableHeaderNavigation,
    },
    {
        onPagePath: locations.UPDATE_PASSWORD.path,
        navigation: landingLink,
    },
    {
        onPagePath: locations.SIGN_IN.path,
        navigation: landingLink,
    },
];

const siteNavigationList = {
    aph: [...aphNavigateTo, ...sharedNavigateTo],
    beess: [...pdaNavigateTo, ...sharedNavigateTo],
    doeoss: [...doeOssNavigateTo, ...sharedNavigateTo],
    doh: [...dohNavigateTo, ...sharedNavigateTo],
    earlyaccess: [...earlyAccessNavigateTo, ...sharedNavigateTo],
    fcrr: [...fcrrNavigateTo, ...sharedNavigateTo],
    fgrbi: [...fgrbiNavigateTo, ...sharedNavigateTo],
    ficw: [...ficwNavigateTo, ...sharedNavigateTo],
    mapr: [...maprNavigateTo, ...sharedNavigateTo],
};

export function getHeaderNavigation(siteDetails, path, isLoggedIn, isAdmin) {
    const siteNavigation = siteNavigationList[getSiteAbbreviationForProperties(siteDetails)];
    const pageNavigation = findObjectArrayByField(siteNavigation, path, "onPagePath");
    return pageNavigation ? pageNavigation.navigation(isLoggedIn, isAdmin) : dashboardLink(isLoggedIn, isAdmin);
}