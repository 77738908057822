import {config, mockResolveApiCall, requestOptions, requestTypes, sendRequest,} from "../../Utilities/Api";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {getCatalog} from "../Category/CategorySearch/categorySearchMockData";
import {getCourses} from "./courseMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerCourseApi {
	static getAllCourses(siteGroupId) {
		siteGroupId = siteGroupId || 0;
		return sendRequest(
			requestOptions(
				config.URL + `Courses/sites/${siteGroupId}`,
				requestTypes.GET,
			),
		);
	}

	static getCourse(courseId) {
		return sendRequest(
			requestOptions(
				config.URL + `Courses/${courseId}`,
				requestTypes.GET
			),
		);
	}

	static getCategories(siteGroupId, type = 0) {
		return sendRequest(
			requestOptions(
				config.URL + `Categories/sites/${siteGroupId}/type/${type}`,
				requestTypes.GET,
			),
		);
	}

	static getAllCategories() {
		return sendRequest(
			requestOptions(
				config.URL + `Categories`,
				requestTypes.GET,
			),
		);
	}
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockCourseApi {
    	static getAllCourses(siteGroupId) {
		let allCourses = getCourses();
		const sortProp = "courseTitle";
		const sortedCourses = allCourses
			.sort(function (a, b) {
				return a[sortProp] > b[sortProp] ? 1 : b[sortProp] > a[sortProp] ? -1 : 0;
			});

		if (isNullOrUndefined(siteGroupId) || siteGroupId.toString() === "0")
			return mockResolveApiCall(sortedCourses);

		return mockResolveApiCall(sortedCourses.filter(f => f.siteGroupId.toString() === siteGroupId.toString()));
	}

	static getCourse(courseId) {
		let allCourses = getCourses();
		return mockResolveApiCall(allCourses.find(f => f.courseId.toString() === courseId));
	}

	static getCategories() {
		const catalog = getCatalog();
		return mockResolveApiCall(catalog);
	}

	static getAllCategories() {
		const catalog = getCatalog();
		return mockResolveApiCall(catalog);
	}
}

const CourseApi = config.environment.MODE === config.modes.SERVER ? ServerCourseApi : MockCourseApi;
export default CourseApi;