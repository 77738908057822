import {courseFilterType} from "../Components/Course/courseFilterConstants";
import {
    APH_SITE_GROUP_ID,
    DOE_OSS_SITE_GROUP_ID,
    DOH_SITE_GROUP_ID,
    EARLY_ACCESS_SITE_GROUP_ID,
    FCRR_SITE_GROUP_ID,
    FGRBI_SITE_GROUP_ID,
    FICW_SITE_GROUP_ID, MAPR_SITE_GROUP_ID,
    PDA_SITE_GROUP_ID
} from "./siteConstants";
import {ROLE_NAMES} from "../Components/Authorize/roleConstants";

export const siteDetails = [
    {
        cdnDirectory: "aph",
        cdnColor: "#f29c06",
        cssVersion: "1.0",
        siteAbbreviation: "APH",
        documentTitlePostFix: "APH Hive",
        siteName: "APH Hive",
        siteGroupId: APH_SITE_GROUP_ID,
        supportEmail: "support+aph@fcim.org",
        contentSupportEmail: "outreach@aph.org",
        contentSupportLink: "<a href='//forms.office.com/r/70h9rSD941' target='_blank' rel='noopener noreferrer'>Hive User Support Form</a>",
        defaultCourseContactMessage: "",
        gaTrackingCode: "UA-173507487-1",
        gaTrackingCode4: "G-QYW0F31X2J",
        canRegister: true,
        hasImage: true,
        requiresFollowUpActivityApproval: true,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant],
        registrationEmailHelpText: "Same email as the APH Shopping site if applicable.",
        privacyPolicy: "<p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <a href='//www.aph.org/privacy-policy/' target='_blank' rel='noopener noreferrer'>privacy policy</a>.</p>",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=APH&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>. For content support: email <a href='mailto:support+aphContent@fcim.org'>support+aphContent@fcim.org</a>.<br/>&copy;2020 American Printing House",
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='APH'`,
        courseEventTitle: "Manage ACVREP Events",
        hasCourseEvent: true,
        professionalCommunitiesTitle: "Manage Professional Communities",
        hasProfessionalCommunities: true,
        courseFilters: [courseFilterType.textFieldCourseTitleDescription, courseFilterType.searchableSubjectTags],
        requiresLicenseAgreement: true,
        canRegisterAllowList: [],
        discussionBoardUrl: "https://community.aphhive.org/c/core-curriculum/",
        discussionBoardHtml: "<p>You are leaving The APH Hive (https://aphhive.org) and going to a discussion board maintained by Discourse (https://community.aphhive.org/).</p><p>Discourse has a privacy policy that is different from the APH Hive. APH is not responsible for the services and content on Discourse.</p><p>Do you want to continue to Discourse?</p>",
        discussionBoardButtonLabel: "Continue to Discourse",
        hasDiscussionBoards: true,
        usesLocationForUserSearch: true,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: true,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "",
        extendCategoryTypes: true
    },
    {
        cdnDirectory: "pda",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "PDA",
        documentTitlePostFix: "BEESS Portal",
        siteName: "BEESS Portal",
        siteGroupId: PDA_SITE_GROUP_ID,
        supportEmail: "support+beessportal@fcim.org",
        contentSupportEmail: "BEESSPortal@fldoe.org",
        contentSupportLink: "",
        defaultCourseContactMessage: "For content related questions, email <a href='mailto:beesssupport@fldoe.org'>beesssupport@fldoe.org</a>. For technical issues, call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&helpdesk_ticket%5bsubject%5d=BESE&disable%5bsubject%5d=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>.",
        gaTrackingCode: "UA-49861537-2",
        canRegister: true,
        hasImage: false,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.AllDataViewer, ROLE_NAMES.RegionalDataViewer, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant],
        registrationEmailHelpText: "",
        privacyPolicy: "<p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes.</p>",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&helpdesk_ticket%5bsubject%5d=BEESS&disable%5bsubject%5d=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>. <br/>Funded by the Florida Department of Education, Bureau of Exceptional Education and Student Support.",
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='BEESS'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [courseFilterType.searchableCourseTitle],
        requiresLicenseAgreement: true,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: false,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "Please check with your school district or FDLRS PLA coordinator, PRIOR to retaking this course to ensure that they will award additional in-service points for retaking the course after 5-years.",
        extendCategoryTypes: false
    },
    {
        cdnDirectory: "fgrbi",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "FGRBI",
        documentTitlePostFix: "FGRBI",
        siteName: "Family Guided Routines Based Intervention and Caregiver Coaching",
        siteGroupId: FGRBI_SITE_GROUP_ID,
        supportEmail: "support+fgrbi@fcim.org",
        contentSupportEmail: "katjcripe@gmail.com",
        contentSupportLink: "",
        defaultCourseContactMessage: "",
        gaTrackingCode: "UA-173508842-1",
        canRegister: false,
        hasImage: true,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant],
        registrationEmailHelpText: "",
        privacyPolicy: "<p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes.</p>",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=FGRBI&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>. For content support: email <a href='mailto:support+fgrbiContent@fcim.org'>support+fgrbiContent@fcim.org</a>.",
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='FGRBI'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [],
        requiresLicenseAgreement: true,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: true,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "",
        extendCategoryTypes: false
    },
    {
        cdnDirectory: "earlyAccess",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "Early Access",
        documentTitlePostFix: "Early Access",
        siteName: "Early Access Service Coordinator Training",
        siteGroupId: EARLY_ACCESS_SITE_GROUP_ID,
        supportEmail: "support+earlyAccess@fcim.org",
        contentSupportEmail: "melissa.schnurr@iowa.gov",
        contentSupportLink: "",
        defaultCourseContactMessage: "",
        gaTrackingCode: "",
        canRegister: true,
        hasImage: true,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant],
        registrationEmailHelpText: "",
        privacyPolicy: "<p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes.</p>",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=EarlyAccess&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>.<br/>For content questions contact your Regional Liaison or Melissa Schnurr at <a href='mailto:support+earlyAccessContent@fcim.org'>support+earlyAccessContent@fcim.org</a>.",
        showFooterSignOut: false,
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='Early Access'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [],
        requiresLicenseAgreement: true,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: true,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "",
        extendCategoryTypes: false
    },
    {
        cdnDirectory: "ficw",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "FICW",
        documentTitlePostFix: "FICW",
        siteName: "FICW",
        siteGroupId: FICW_SITE_GROUP_ID,
        supportEmail: "support+ficw@fcim.org",
        contentSupportEmail: "",
        contentSupportLink: "",
        defaultCourseContactMessage: "",
        gaTrackingCode: "",
        canRegister: true,
        hasImage: true,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant],
        registrationEmailHelpText: "",
        privacyPolicy: "<p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes.</p>",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=EarlyAccess&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>.<br/>For content questions contact your Regional Liaison or Melissa Schnurr at <a href='mailto:support+earlyAccessContent@fcim.org'>support+earlyAccessContent@fcim.org</a>.",
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='FICW'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [],
        requiresLicenseAgreement: true,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: true,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "",
        extendCategoryTypes: false
    },
    {
        cdnDirectory: "fcrr",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "FCRR",
        documentTitlePostFix: "FCRR",
        siteName: "FCRR",
        siteGroupId: FCRR_SITE_GROUP_ID,
        supportEmail: "support+fcrr@fcim.org",
        contentSupportEmail: "",
        contentSupportLink: "",
        defaultCourseContactMessage: "",
        gaTrackingCode: "",
        canRegister: true,
        hasImage: true,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant],
        registrationEmailHelpText: "",
        privacyPolicy: "<p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes.</p>",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=EarlyAccess&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>.<br/>For content questions contact your Regional Liaison or Melissa Schnurr at <a href='mailto:support+earlyAccessContent@fcim.org'>support+earlyAccessContent@fcim.org</a>.",
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='FCRR'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [],
        requiresLicenseAgreement: false,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: true,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "",
        extendCategoryTypes: false
    },
    {
        cdnDirectory: "mapr",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "MAPR",
        documentTitlePostFix: "MAP-R",
        siteName: "MAP-R",
        siteGroupId: MAPR_SITE_GROUP_ID,
        supportEmail: "support+mapr@fcim.org",
        contentSupportEmail: "",
        contentSupportLink: "",
        defaultCourseContactMessage: "",
        gaTrackingCode: "",
        canRegister: true,
        hasImage: true,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.TrackingStudent, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.TrackingStudent],
        registrationEmailHelpText: "",
        privacyPolicy: "",
        footer: "Morphological Analysis Pathway to Reading",
        showFooterSignOut: true,
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='MAPR'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [],
        requiresLicenseAgreement: false,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: false,
        usesStudentImport: true,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "",
        extendCategoryTypes: false
    },
    {
        cdnDirectory: "doh",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "DOH",
        documentTitlePostFix: "DOH",
        siteName: "DOH",
        siteGroupId: DOH_SITE_GROUP_ID,
        supportEmail: "support+DOH@fcim.org",
        contentSupportEmail: "",
        contentSupportLink: "",
        defaultCourseContactMessage: "",
        gaTrackingCode: "",
        gaTrackingCode4: "G-NXV2ZHNZZF",
        canRegister: true,
        hasImage: true,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant],
        registrationEmailHelpText: "",
        privacyPolicy: "<p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes.</p>",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=EarlyAccess&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>.<br/>For content questions contact your Regional Liaison or Melissa Schnurr at <a href='mailto:support+earlyAccessContent@fcim.org'>support+earlyAccessContent@fcim.org</a>.",
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='DOH'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [],
        requiresLicenseAgreement: false,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: true,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: false,
        courseTrainingTitle: "",
        hasCourseTraining: false,
        zoomDataApiKey: "",
        completionWarningMessage: "",
        reEnrollMessage: "",
        extendCategoryTypes: false
    },
    {
        cdnDirectory: "doeOss",
        cdnColor: "#fff",
        cssVersion: "1.0",
        siteAbbreviation: "DOE OSS",
        documentTitlePostFix: "DOEOSS",
        siteName: "DOEOSS",
        siteGroupId: DOE_OSS_SITE_GROUP_ID,
        supportEmail: "support+DOEOSS@fcim.org",
        contentSupportEmail: "support+DOEOSS@fcim.org",
        contentSupportLink: "",
        defaultCourseContactMessage: "",
        gaTrackingCode: "",
        canRegister: true,
        hasImage: true,
        requiresFollowUpActivityApproval: false,
        rolesUsedBySite: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.AllAccessParticipant, ROLE_NAMES.DistrictDataViewer, ROLE_NAMES.Trainer, ROLE_NAMES.DistrictDataViewerAndTrainer, ROLE_NAMES.SiteAdmin, ROLE_NAMES.TechSupport, ROLE_NAMES.SystemAdmin],
        rolesThatAdminCanManage: [ROLE_NAMES.DemoParticipant, ROLE_NAMES.Participant, ROLE_NAMES.Trainer, ROLE_NAMES.DistrictDataViewer, ROLE_NAMES.DistrictDataViewerAndTrainer],
        registrationEmailHelpText: "",
        privacyPolicy: "",
        footer: "For technical support: call (800) 357-1072 or <a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=EarlyAccess&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">create a support ticket</a>.<br/>For content questions contact your Regional Liaison or Melissa Schnurr at <a href='mailto:support+earlyAccessContent@fcim.org'>support+earlyAccessContent@fcim.org</a>.",
        initialFollowUpActivityDirections: "",
        reviseFollowUpActivityDirections: "",
        freshDeskUrl: `https://fcim.freshdesk.com/widgets/feedback_widget/new?&searchArea=no&disable[subject]=true&helpdesk_ticket[subject]='DOH'`,
        courseEventTitle: "",
        hasCourseEvent: false,
        hasProfessionalCommunities: false,
        courseFilters: [],
        requiresLicenseAgreement: true,
        canRegisterAllowList: [],
        discussionBoardUrl: "",
        discussionBoardHtml: "",
        discussionBoardButtonLabel: "",
        hasDiscussionBoards: false,
        usesLocationForUserSearch: true,
        usesStudentImport: false,
        allowSiteAdminsCourseEdit: false,
        allowSiteAdminManageDeletingUsers: true,
        courseTrainingTitle: "Manage Trainings",
        hasCourseTraining: true,
        zoomDataApiKey: "Fhuwd4pURUKDV4WygqqqZQ",
        completionWarningMessage: "Your certificate will be available once your training has been verified.",
        reEnrollMessage: "",
        extendCategoryTypes: false
    },

];
export const aphSiteDetails = siteDetails.find(f => f.siteGroupId === APH_SITE_GROUP_ID);
export const fgrbiSiteDetails = siteDetails.find(f => f.siteGroupId === FGRBI_SITE_GROUP_ID);
export const fcrrSiteDetails = siteDetails.find(f => f.siteGroupId === FCRR_SITE_GROUP_ID);
export const ficwSiteDetails = siteDetails.find(f => f.siteGroupId === FICW_SITE_GROUP_ID);
export const doeOssSiteDetails = siteDetails.find(f => f.siteGroupId === DOE_OSS_SITE_GROUP_ID);
export const dohSiteDetails = siteDetails.find(f => f.siteGroupId === DOH_SITE_GROUP_ID);
export const pdaSiteDetails = siteDetails.find(f => f.siteGroupId === PDA_SITE_GROUP_ID);
export const earlyAccessSiteDetails = siteDetails.find(f => f.siteGroupId === EARLY_ACCESS_SITE_GROUP_ID);
export const maprSiteDetails = siteDetails.find(f => f.siteGroupId === MAPR_SITE_GROUP_ID);
