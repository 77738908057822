import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {searchModel} from "../../../Utilities/Search";
import {courseTypes} from "../../Course/courseConstants";

export const categorySearchResults = (attributes) => ({...attributes});

export const categorySearchModel = (userId, siteGroupId, categoryInfo = "", isActive = "", parentsOnly = false, categoryType = courseTypes.RegularCourse.id) => {
    return Object.assign({}, searchModel(userId.toString(), "title", false, "title"), {
        categoryInfo: categoryInfo ? categoryInfo : "",
        isActive: !isNullOrUndefined(isActive) ? isActive : "Yes",
        siteGroupId,
        parentsOnly: parentsOnly,
        categoryType: categoryType, 
        ClearCategorySearch: clearCategorySearch,
    });
};

function clearCategorySearch() {
    this.categoryInfo = "";
    this.isActive = "";
    this.ClearSearch(false);
}