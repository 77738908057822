import {createYesNoObject} from "../../../Components/Inputs/inputUtility";
import {APH_SITE_GROUP_ID, MOCK_SITE_GROUP_ID} from "../../../SiteConfig/siteConstants";
import {convertToNumber, isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {
    emptyFunction,
    handleApiCall,
    redirect,
} from "../../../Utilities/testingUtilities";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";
import {isNumber} from "../../../Utilities/Types/typeUtilities";
import {ALL_COURSES} from "../../Catalog/catalogConstants";
import {categorySearchModel, categorySearchResults} from "./categorySearchFactory";
import {searchMockData} from "../../../Utilities/Search";
import {dynamicSort} from "../../../Utilities/Sort";

const isLoading = false;
const handleSetCategorySearchCriteria = emptyFunction;
const categorySearchCriteria = categorySearchModel(0, MOCK_SITE_GROUP_ID);

export const props = {
    handleApiCall,
    handleSetCategorySearchCriteria,
    isLoading,
    redirect,
    categorySearchCriteria
};

let allCategories = null;
const yesNoOptions = createYesNoObject(false);

const categoryData = [
    {
        categoryId: 1,
        title: `Assessment`,
        description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ultricies, ligula ut aliquet vestibulum, felis dolor congue diam, sed dictum purus augue sit amet elit. Donec velit odio, iaculis ac tristique et, ullamcorper quis arcu. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce ut erat libero. Phasellus.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: null,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 2,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 3,
        title: `Core Curriculum`,
        description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec feugiat imperdiet nulla ullamcorper tincidunt. Ut sagittis velit ex, sed pellentesque orci dignissim a. Vestibulum placerat, est id luctus tincidunt, nibh nibh bibendum massa, non mollis metus massa at augue. Ut.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: null,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 3,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 5,
        title: `English Language Arts`,
        description: `<p>Nulla elit arcu, faucibus vel facilisis quis, vehicula at tortor. Ut sit amet nibh pellentesque, tempor felis a, facilisis risus. In quis libero nec orci maximus fermentum eget sed urna. Nullam consectetur hendrerit mattis. Curabitur.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 3,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 1,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 6,
        title: `Science and Health`,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 3,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 2,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 7,
        title: `Social Studies`,
        description: `<p>Nulla elit arcu, faucibus vel facilisis quis, vehicula at tortor. Ut sit amet nibh pellentesque, tempor felis a, facilisis risus. In quis libero nec orci maximus fermentum eget sed urna. Nullam consectetur hendrerit mattis. Curabitur.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 3,
        parentTitle: "",
        hasCourses: yesNoOptions.No,
        displayOrder: 3,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 8,
        title: `Mathematics`,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.No,
        parentId: 3,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 4,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 9,
        title: `Career and Technical Education `,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.No,
        parentId: 3,
        parentTitle: "",
        hasCourses: yesNoOptions.No,
        displayOrder: 5,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 10,
        title: `Physical Education`,
        description: `<p>Nulla elit arcu, faucibus vel facilisis quis, vehicula at tortor. Ut sit amet nibh pellentesque, tempor felis a, facilisis risus. In quis libero nec orci maximus fermentum eget sed urna. Nullam consectetur hendrerit mattis. Curabitur.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 3,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 6,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 11,
        title: `Fine Arts`,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 3,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 7,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 2,
        title: `Early Childhood`,
        description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec feugiat imperdiet nulla ullamcorper tincidunt. Ut sagittis velit ex, sed pellentesque orci dignissim a. Vestibulum placerat, est id luctus tincidunt, nibh nibh bibendum massa, non mollis metus massa at augue. Ut.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: null,
        parentTitle: "",
        hasCourses: yesNoOptions.No,
        displayOrder: 4,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 4,
        title: `Expanded Core Curriculum`,
        description: `<p>Maecenas pharetra est ac mauris venenatis, ut egestas nisi molestie. Etiam vestibulum nunc metus, ac molestie eros eleifend ut. Nunc gravida arcu mattis ex commodo vestibulum. Morbi elementum urna massa, quis luctus neque consequat vel. Donec fermentum ultrices vulputate. In pulvinar ac erat lobortis blandit. Pellentesque feugiat maximus.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: null,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 5,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 12,
        title: `Compensatory Skills`,
        description: `<p>Nulla elit arcu, faucibus vel facilisis quis, vehicula at tortor. Ut sit amet nibh pellentesque, tempor felis a, facilisis risus. In quis libero nec orci maximus fermentum eget sed urna. Nullam consectetur hendrerit mattis. Curabitur.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 1,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 13,
        title: `Orientation and Mobility`,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 2,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 14,
        title: `Social Interaction Skills`,
        description: `<p>Nulla elit arcu, faucibus vel facilisis quis, vehicula at tortor. Ut sit amet nibh pellentesque, tempor felis a, facilisis risus. In quis libero nec orci maximus fermentum eget sed urna. Nullam consectetur hendrerit mattis. Curabitur.</p>`,
        isActive: yesNoOptions.No,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 3,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 15,
        title: `Independent Living Skills`,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.No,
        displayOrder: 4,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 16,
        title: `Recreation and Leisure Skills `,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.No,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.No,
        displayOrder: 5,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 17,
        title: `Career Education and Transition`,
        description: `<p>Nulla elit arcu, faucibus vel facilisis quis, vehicula at tortor. Ut sit amet nibh pellentesque, tempor felis a, facilisis risus. In quis libero nec orci maximus fermentum eget sed urna. Nullam consectetur hendrerit mattis. Curabitur.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 6,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 18,
        title: `Use of Access Technology`,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 7,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 19,
        title: `Sensory Efficiency Skills`,
        description: `<p>Nulla elit arcu, faucibus vel facilisis quis, vehicula at tortor. Ut sit amet nibh pellentesque, tempor felis a, facilisis risus. In quis libero nec orci maximus fermentum eget sed urna. Nullam consectetur hendrerit mattis. Curabitur.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 8,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 20,
        title: `Self-Determination`,
        description: `<p>Nulla facilisis, lectus non sollicitudin faucibus, mauris sem posuere mauris, vitae tempus felis nisi quis nibh. Ut sed mauris vitae ligula ornare tincidunt sit amet ut ante. Praesent imperdiet lectus commodo, dignissim dui nec, iaculis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum.</p>`,
        isActive: yesNoOptions.Yes,
        parentId: 4,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 9,
        siteGroupId: APH_SITE_GROUP_ID
    },
    {
        categoryId: 21,
        title: ALL_COURSES,
        description: ``,
        isActive: yesNoOptions.Yes,
        parentId: null,
        parentTitle: "",
        hasCourses: yesNoOptions.Yes,
        displayOrder: 1,
        siteGroupId: APH_SITE_GROUP_ID
    }
];

export const categoryId = categoryData[0].categoryId.toString();

function getCategoryParentTitle(categories, parentId) {
    if(!parentId)
        return "";

    const parentCategory = categories.find(f => f.categoryId.toString() === parentId.toString());

    return !parentCategory ? "" : parentCategory.title;
}

export function getCategories(reset = false) {
    if (isNullOrUndefined(allCategories) || reset)
        allCategories = categoryData.map((category) => {
            return {...category, ...{parentTitle: getCategoryParentTitle(categoryData, category.parentId)}}
        });

    return allCategories;
}

export function getCategory(categoryId) {
    let category = getCategories().find(f => f.categoryId.toString() === categoryId.toString());
    if(!category)
        return {};

    category.archived = category.isActive === yesNoOptions.No;

    return category;
}

export function getCatalog() {
    const activeCategories = getCategories().filter(f => f.isActive === yesNoOptions.Yes);

    let parentCategories = activeCategories.filter(f => f.parentId === null);
    parentCategories.sort(dynamicSort("displayOrder"));
    return parentCategories.map((category) => createCatalog(category, activeCategories));
}

function createCatalog(category, allCategories) {
    let childCategories = allCategories.filter(f => f.parentId === category.categoryId);

    childCategories.sort(dynamicSort("displayOrder"));

    return {
        categoryId: category.categoryId,
        title: category.title,
        description: category.description,
        childCategories: childCategories.map((childCategory) => createCatalog(childCategory, allCategories))
    };
}

export function filterSearchResults(searchCriteria) {
    const allCategories = getCategories();

    return searchMockData(allCategories, searchCriteria, categorySearchResults);
}

export function updateCategorySortOrder(newOrder) {
    const allCategories = getCategories();

    for(let index = 0; index < newOrder.length; index++) {
        let category = allCategories.find(f => f.categoryId === newOrder[index]);
        if(category)
            category.displayOrder = index + 1;
    }

    return allCategories;
}

const getNewCategoryId = (allCategories) => {
    let categoryIdAi = 0;
    for(const category of allCategories) {
        if (category.categoryId > categoryIdAi) categoryIdAi = category.categoryId;
    }

    return ++categoryIdAi;
};

export function saveCategory(categoryDetails) {
    let allCategories = getCategories();
    let category = getCategory(categoryDetails.categoryId);

    if(isObjectNullOrEmpty(category)) {
        category = {
            categoryId: getNewCategoryId(allCategories),
            title: categoryDetails.title,
            description: categoryDetails.description,
            isActive: categoryDetails.archived ? yesNoOptions.No : yesNoOptions.Yes,
            parentId: isNumber(categoryDetails.parentId) ? convertToNumber(categoryDetails.parentId) : null,
            parentTitle: getCategoryParentTitle(allCategories, categoryDetails.parentId),
            hasCourses: yesNoOptions.No,
            displayOrder: 0,
            siteGroupId: MOCK_SITE_GROUP_ID
        };
        allCategories.push(category);
    }
    else {
        category.title = categoryDetails.title;
        category.description = categoryDetails.description;
        category.isActive = categoryDetails.archived ? yesNoOptions.No : yesNoOptions.Yes;
        category.parentId = isNumber(categoryDetails.parentId) ? convertToNumber(categoryDetails.parentId) : null;
        category.parentTitle = getCategoryParentTitle(allCategories, categoryDetails.parentId);
    }

    if(!category.parentId && category.isActive === yesNoOptions.No)
        archiveCategoryChildren(allCategories, category.categoryId);

    return category;
}

function archiveCategoryChildren(allCategories, parentId) {
    allCategories.filter(f => f.parentId === parentId).map((category) => category.isActive = yesNoOptions.No);
}